// import { useRef } from "react";


export default function About() {

  // const aboutSection = useRef(null);

  return(
    <section class="sm:py-8 lg:py-20 sm:mx-0 lg:mx-auto" id="aboutSection">
      <div className="flex-none sm:min-w-fit lg:flex md:flex-row lg:gap-0 sm:gap-0 py-8 md:place-content-center">
        <div class="flex place-content-center md:flex-none sm:shrink-0 order-1 ">
          <img
              class="max-h-full max-w-sm m-auto md:max-w-md scale-90"
              src="https://i.imgur.com/wHfH7sQ.jpg"
              alt="profile pic"
          />
        </div>
        <div class="flex lg:max-w-2xl px-3 py-6 text-left self-center flex-auto order-2 place-content-center">
          <div class="container px-6 md:px-8">
            <h2 class="text-4xl font-semibold py-2 text-gray-900 dark:text-white">...but you can call me Marty.</h2>
            <p class="text-xl py-2 text-gray-900 dark:text-white">I’m a software engineer of Pilipino descent with a bachelors in Computer Science and Engineering from the University of California, Merced. </p>
            <p class="text-xl py-2 text-gray-900 dark:text-white">As I rekindled my passion for iOS development, I pursued different domains that piqued my interest such as machine learning, frontend development, and psychology. I hope that my work and culminating experience reaches far enough to impact at least someone's life in a positive way. </p>
            <p class="text-xl py-2 text-gray-900 dark:text-white">Aside from code, I'm either playing a mean French horn, bingewatching the Flash, fiddling with Notion pages, downing cappuchinos, or secretly rating your local food truck's tacos. </p>
          </div>
        </div>
      </div>
    </section>
  );
};