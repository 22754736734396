// import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
// import Jumbotron from './components/jumbotron';
import Footer from './components/footer';
import Banner from './components/banner';
import Projects from './components/projects';
import About from './components/about';
import Services from './components/services';
import { Element } from 'react-scroll';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='md:grid grid-flow-row auto-rows-max absolute inset-x-0 top-0 bg-stone-100 dark:bg-stone-900'>
          <Navbar/> 
          <Banner />
          <Element><Services /></Element>
          <Element><Projects /></Element>
          <Element><About /></Element>
          <Footer />
        </div>
      </header>
    </div>
  );
}

export default App;
