function Banner() {
  return (
    <section class="sm:py-8 lg:py-20 sm:mx-0 lg:mx-auto">
      <div className="flex-none sm:min-w-fit md:flex md:flex-row lg:gap-10 sm:gap-0">
        <div class="flex py-8 scale-75 md:scale-100 sm:flex-none place-content-center md:flex-none sm:shrink-0 order-2 md:order-2">
          <img
            class="rounded-full h-auto max-w-sm"
            src="https://i.imgur.com/2WipRy0.jpg"
            alt="profile pic"
          />
        </div>
        <div class="flex lg:max-w-xl md:px-8 py-6 text-center md:text-left self-center flex-auto order-1 md:order-1 place-content-center">
          <div class="container px-8 md:px-20">
            <h2 class="text-4xl font-semibold py-2 text-gray-900 dark:text-white">Hi, I'm Marthen</h2>
            <p class="text-xl text-gray-900 dark:text-white">I’m a freelance web designer and developer based in the SF Bay Area. I make sites and iOS apps for small businesses in my community.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
