export default function Services() {

  const onSubmitClick = (e) =>  {
    alert("Page under construction.\nFor business inquiries, email me at me@mnodado.com or book a time for us to chat using the link below.\nhttps://bit.ly/488MXCq");
  };

  const plans = [
    {
      name: "App Development",
      price: 12,
      features: [
        "Responsive Website Design",
        "Mobile App Design (iPhone only)",
        "Brainstorming Sessions",
        "Logo and Branding Materials",
        "Search Engine Optimization (SEO)",
        "Post-Launch Support"
      ],
    },
    {
      name: "Design Consulting",
      price: 35,
      features: [
        "Responsive Website Design",
        "Mobile App Design (iPhone only)",
        "Brainstorming Sessions",
        "Logo and Branding Materials",
        "Intuitive Navigation Design",
        "Competitive Market Research",
        "User Experience (UX) Evaluation",
      ],
    },
    {
      name: "Promotional Materials",
      price: 60,
      features: [
        "High-quality Digital Designs",
        "Visually-vibrant Physical Designs",
        "Distinct Business Cards",
        "Logo and Branding Materials",
        "Brainstorming Sessions",
        "Social Media Integration",
        "Print Optimization",
      ],
    },
  ];

  return (
    <section className="py-14" id="servicesSection">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="relative max-w-xl mx-auto sm:text-center">
          <h3 className="text-gray-800 dark: dark:text-gray-100 text-3xl font-bold sm:text-4xl">
            Provided Services
          </h3>
        </div>
        <div className="mt-16 space-y-6 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3">
          {plans.map((item, idx) => (
            <div
              key={idx}
              className="relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2"
            >
              <div>
                <span className="text-blue-500 font-semibold">{item.name}</span>
              </div>
              <ul className="py-8 space-y-3">
                {item.features.map((featureItem, idx) => (
                  <li key={idx} className="flex items-center gap-5 text-lg text-gray-800 dark:text-gray-100 text-left">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {featureItem}
                  </li>
                ))}
              </ul>
              <div className="flex-1 flex items-end">
                <button 
                  className="px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700"
                  onClick={onSubmitClick}
                  >
                  Learn More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
