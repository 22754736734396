import LinkedinIcon from "../assets/images/linkedin.svg";
import GithubIcon from "../assets/images/github.svg";
import EmailIcon from "../assets/images/email.svg";

function Footer() {
  const kofiStyle = {
    border: "0px",
    // height: "36px",
    width: "auto",
  };

  return (
    <footer class="bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-stone-800 dark:border-gray-600">
      <ul class="flex flex-wrap place-content-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0 gap-2 order-1">
        <li>
          <a href="mailto:projects@mnodado.com">
            <img class="size-6" src={EmailIcon} alt="email link"></img>
          </a>
        </li>
        <li>
          <a href="https://github.com/illumimarty">
            <img class="size-6" src={GithubIcon} alt="github link"></img>
          </a>
        </li>
        <li>
          <a href="https://linkedin.com/in/marthen-r-nodado">
            <img class="size-6" src={LinkedinIcon} alt="linkedin link"></img>
          </a>
        </li>
        <li>
          <a href="https://ko-fi.com/S6S1L4QT8">
            <img
              class="size-7"
              style={kofiStyle}
              src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
              border="0"
              alt="Buy Me a Coffee at ko-fi.com"
            />
          </a>
        </li>
      </ul>
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2024 Marthen Nodado. All Rights Reserved.
      </span>
    </footer>
  );
}

export default Footer;
